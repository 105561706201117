import React from "react";
import { Link } from "gatsby";
import { ExtendedHeader, EnvironmentContext } from "~components/header";
import Footer from "~components/footer";
import { HomeTitle } from "~components/text-helpers";
import { FeatureCard } from "~components//cards";
import { css } from "@emotion/react";
import ROBOTHOR_VIDEO from "~icons/robothor-white.svg";
import color from "~styles/color";
import SiteHelmet from "~components/site-helmet";

// GIFs!
import pairingsGIF from "~media/robothor-pairings.gif";
import modularGIF from "~media/robothor-modular.gif";
import reconfigurableGIF from "~media/robothor-reconfiguration.gif";
import accessibleGIF from "~media/robothor-accessible.gif";

export default function Home() {
  return (
    <>
      <SiteHelmet coverImage="https://ai2thor.allenai.org/media/robothor-meta.jpg">
        <title>RoboTHOR</title>
        <meta name="keywords" content="RoboTHOR, Sim2Real, real, reality" />
        <meta
          name="description"
          content="RoboTHOR is an environment within the AI2-THOR framework, designed to develop embodied AI agents. It consists of a series of scenes in simulation with counterparts in the physical world."
        />
      </SiteHelmet>
      <ExtendedHeader
        videoLink="https://player.vimeo.com/video/509326657?autoplay=1&loop=1&muted=1"
        upperContent={
          <EnvironmentContext
            logo={ROBOTHOR_VIDEO}
            description="
              RoboTHOR is an environment within the AI2-THOR framework, designed
              to develop embodied AI agents. It consists of a series of scenes
              in simulation with counterparts in the physical world.
          "
          />
        }
      />
      <div
        css={css`
          text-align: center;
          padding-bottom: 80px;
          padding-top: 30px;
        `}
      >
        <HomeTitle title="Key Features" />
        <FeatureCard
          title="Sim & Real Pairings"
          description="For each physical room, there is a corresponding synthetic equivalent which enables us to study the discrepancies in appearance and control."
          gif={pairingsGIF}
        />
        <FeatureCard
          title="Modular"
          description="The apartments are built in a modular fashion, drawing from an asset library."
          gif={modularGIF}
        />
        <FeatureCard
          title="Reconfigurable"
          description="The physical environments are built using modular and movable components, allowing us to host scenes with vastly different layouts within a single physical space."
          gif={reconfigurableGIF}
        />
        <FeatureCard
          title="Accessible to All"
          description="The simulation environment is open source. Researchers will be able to remotely deploy their models in the real environment."
          gif={accessibleGIF}
        />
      </div>
      <Footer />
    </>
  );
}
